<template>
  <div class="productId">
    <!-- 背景 -->
    <div class="header-swiper">
      <!-- 蒙版 -->
      <div class="mast"></div>
      <!-- 轮播图 -->
      <el-carousel height="40vh">
        <el-carousel-item class="show-img" v-for="bg in appinfo.img_list" :key="bg">
          <img class="swiper" :src="bg" alt="" />
        </el-carousel-item>
      </el-carousel>
      <!-- 头部信息 -->
      <div class="hearder-info">
        <div class="app-logo">
          <img :src="appinfo.logo" alt="" />
        </div>
        <div class="app-data">
          <div class="name">
           <div class="text">
              {{ appinfo.name }}
           </div>
            <div class="info_num">
              <div class="read_num">
                <i class="iconfont icon-yueduliang"></i>
                {{ appinfo.read_num }}
              </div>
              <div class="read_num">
                <i class="iconfont icon-weishiyong"></i>
                {{ appinfo.use_num }}
              </div>
            </div>
          </div>
          <div class="date">{{ appinfo.date }}</div>
          <div class="tags">
            <div
              v-for="(tag, index) in appinfo.about"
              :key="index"
              style="background: #3d5aeb"
            >
              {{ tag }}
            </div>
          </div>
        </div>
        <div :class="appinfo.status===1?'app-but':'app-but not_use'">
          <a v-show="appinfo.status!==1" href="#">在线体验</a>
          <a v-show="appinfo.status===1" :href="appinfo.use_url">在线体验</a>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="app-main">
      <div class="main-data" v-html="appinfo.data"></div>
      <!-- 评论区 -->
      <comment
        :updateComment="getProductComment"
        :sendComment="sendComment"
        :moment="moment"
      />
    </div>
  </div>
</template>

<script>
import comment from "../../../components/comment.vue";
import formatDate from "../../../utils/formatDate";
import myStore from "../../../utils/localStore";

export default {
  name: "productDefault",
  props: ["productId"],
  components: { comment },
  data() {
    return {
      userinfo: myStore.getStore("userinfo"),
      appinfo: {},
      query: {
        pagesize: 10,
        pagenum: 1,
        total: 0,
        maxpage: 5,
      }, // 评论查询数据
      moment: [], // 评论数据
      getLoading:false // 评论数据获取中
    };
  },
  mounted() {
    this.getAppInfo();
    // this.getProductComment()
  },

  methods: {
    // 获取项目数据
    async getAppInfo() {
      const { data: res } = await this.$http.get(
        `product_info/?id=${this.productId}`
      );
      if (res.code !== "200") return;
      this.appinfo = res.data;
      this.query.total = res.data.total;
      // 数据处理
      this.appinfo.about = this.appinfo.about.split(",");
      // 时间数据格式化
      this.appinfo.date = formatDate.format2(this.appinfo.date);
    },

    // 获取评论数据
    async getProductComment() {
      // 判断是否为最后
      if (this.query.maxpage < this.query.pagenum) return;
      if(this.getLoading) return
      this.getLoading = true
      let { data: res } = await this.$http.get(
        `product_comment/?id=${this.productId}&pagesize=${this.query.pagesize}&pagenum=${this.query.pagenum}`
      );
      if (res.code !== "200") return;
      this.getLoading = false
      // 格式化
      res.data.list.forEach((v) => {
        v.datetime = formatDate.format1(v.datetime);
      });
      this.moment = [...this.moment, ...res.data.list];
      this.query.total = res.data.total;
      this.query.maxpage = res.data.maxpage;

      //  为下一次刷新准备
      this.query.pagenum += 1;
    },

    // 接收，发送评论数据
    async sendComment(form, backId) {
      let form_data = {
        id: this.appinfo.id,
        user_id: this.userinfo.id,
        data: form,
      };
      if (backId) {
        form_data["parent"] = backId;
      }

      let { data: res } = await this.$http.post("product_comment/", form_data);
      if(res.code!=='208'){
        // token过期
         this.$notify({
          title: '登录过期',
          message: '请退出并重新登录'
        });
      }
      if (res.code !== "200") return;
      // 向前添加数据
      if (backId) {
        // 回复露出
         this.moment.forEach(v=>{
           if(v.id===backId){
             v.pl_data.unshift({user:this.userinfo.username, data:form})
           }
         })
      } else {
        // 回复项目
        res.data.datetime = formatDate.format1(res.data.datetime);
        this.moment.unshift(res.data);
      }
    },
  },
};
</script>

<style lang='less' scoped>

// 轮播图内部图片
.show-img{
  width: 100%;
  height: 100%;
  text-align: center;
}

.swiper{
  width: 100%;
  height: 100%;
}

.productId {
  width: 100%;
  position: relative;
  .header-swiper {
    position: relative;
    width: 100%;

    .mast {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: 100;
    }

    // 头部信息
    .hearder-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 120px;
      z-index: 101;

      .app-logo {
        float: left;
        padding: 10px 20px;
        padding-left: 30px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }

      .app-data {
        float: left;
        padding-top: 28px;

        .name {
          font-size: 24px;
          height: 55px;
          padding-top: 10px;
          font-weight: 700;
          color: rgb(255, 255, 255);
          position: relative;

          .text{
            float: left;
          }

          .info_num {
            float: left;
            margin-left: 30px;
            height: 30px;

           

            .read_num {
              float: left;
              width: 80px;
              height: 30px;
              background: #3d5aeb;
              font-size: 18px;
              color: #fff;
              text-align: center;
              line-height: 30px;
              border-radius: 13px;
              margin-right: 20px;
            }
          }
        }
        .date {
          // clear: left;
          float: left;
          margin-top: 4px;
          color: rgb(255, 255, 255);
        }

        .tags {
          margin-left: 20px;
          height: 24px;
          width: 320px;
          float: left;


          div {
            display: inline;
            margin: 0;
            height: 100%;
            padding: 1px 8px;
            padding-bottom: 3px;
            font-size: 15px;
            color: #fff;
            background: rgb(236, 14, 25);
            border-radius: 15px;
            margin: 0 3px;
            box-shadow: 2px 2px 1px rgb(155, 155, 155);
          }
        }
      }

      .app-but {
        float: right;
        margin-right: 40px;
        margin-top: 40px;
        width: 100px;
        height: 40px;
        background: #3d5aeb;
        text-align: center;
        line-height: 40px;
        border-radius: 15px;
        box-shadow: 1px 1px 1px rgb(29, 25, 95);
        a{
           color: #fff;
        }
      }
      .not_use{
        background: #9e9e9e;
        box-shadow: 1px 1px 1px rgb(0, 0, 0);
      }
    }
  }

  .app-main {
    position: absolute;
    top: 40vh;
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 30px;

    .main-data {
      margin-bottom: 20px;
    }
  }
}

</style>